// Library Imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Local Imports
import BreadCrumb from '../../../../../components/BreadCrumb';
import Button from '../../../../../components/button';
import TextField from '../../../../../components/inputField';
import {
  yellow,
  gray,
  secondaryColor,
} from '../../../../../utils/style/GlobalVariables';
import { Styles } from '../../../../../utils/style/GlobalStyles';
import { previewReducer } from '../../../../../redux/reducers/previewReducer';
import ApiController from '../../../../../utils/network/api';
import { InsideSpinner } from '../../../../../components/Spinner/Spinner';
import {
  getCurrentDate,
  replaceBase64ToUrlContent,
  replaceBase64ToLocalURL,
} from '../../../../../utils/utils';
import { BLOGURLFORWEB } from '../../../../../utils/network/constants';
import QuilEditor from '../../../../../components/Editor/QuilEditor';
import BlogTabs from './components';

const AddNewBlogPage = () => {
  const { id } = useParams();
  //REFS

  const date = getCurrentDate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [blogData, setBlogData] = useState({});
  const [newPost, setNewPost] = useState({
    title: '',
    description: '<p>This is a sample text.</p>',
    saveDraft: false,
    publishPost: false,
    publish: 'IMMEDIATELY',
    visibility: 'public',
    url: BLOGURLFORWEB,
    customURI: 'enter-uri',
    password: '',
    publishDate: date,
    blogTitle: '',
    keywords: '',
    blogMetaDescription: '',
  });

  const [links, setLinks] = useState([]);
  const [newPostMedia, setNewPostMedia] = useState({
    featureImage: '',
    blogImages: [],
    blogVideos: [],
    featureFrom: 'onChange',
    featureImgForPayload: '',
  });
  const [backendError, setBackendError] = useState({});
  const [loading, setLoading] = useState(false);
  const [getBlogPostLoading, setGetBlogPostLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);

  // Handle OnChange Text Fields
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (name === 'keywords') {
      setNewPost((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === 'title') {
      const uriValue = value
        ?.replace(/[^a-zA-Z0-9\s-]/g, '')
        ?.trim()
        ?.replace(/\s+/g, '-');
      setNewPost((prev) => ({
        ...prev,
        customURI: uriValue === '' ? 'enter-uri' : uriValue?.toLowerCase(),
        [name]: value,
      }));
    } else {
      setNewPost((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Hanlde Preview The Post Page
  const handlePreviewPostPage = () => {
    const imageURL =
      newPostMedia.featureFrom === 'link'
        ? newPostMedia?.featureImage?.url
        : URL.createObjectURL(newPostMedia?.featureImage);
    const postDetails = {
      title: newPost?.title,
      blog: content,
      featureImage: imageURL,
      tags: tagsList,
      from: 'blog',
    };
    dispatch(previewReducer(postDetails));
    window.open('/preview-page', '_blank');
  };

  // CREATE PAYLOAD
  const createPayload = (action, id) => {
    const text = replaceBase64ToLocalURL(content);
    const today = new Date().toISOString().split('T')[0];
    const publishDate = newPost?.publishDate;
    const isDateMatched = today === publishDate;
    console.log('isDateMatched', isDateMatched);
    const data = {
      title: newPost.title,
      description: text,
      publish: action === 'draft' ? null : isDateMatched ? true : false,
      visibility: newPost.visibility?.toUpperCase(),
      password: newPost.password,
      tags: tagsList,
      keywords: links?.map((item) => ({
        word: item.keyword,
        link: item.link,
      })),
      scheduled_publish_date: `${publishDate}T00:00:00`,
      ...(id
        ? {
            end_url_during_update: newPost.customURI,
          }
        : {
            end_url: newPost.customURI,
          }),
      blog_seo_title: newPost?.blogTitle || null,
      blog_seo_description: newPost?.blogMetaDescription || null,
      blog_seo_keywords:
        newPost?.keywords?.trim() !== ''
          ? newPost?.keywords
              ?.trim()
              ?.split(',')
              ?.filter((item) => item !== '' && item !== ' ')
          : null,
    };
    const payload = {
      data,
      blogId: id,
    };
    return payload;
  };

  // CREATE IMAGES PAYLOAD FOR BLOG
  const createPayloadForImagesUpload = (id) => {
    let formData = new FormData();
    if (newPostMedia.featureImgForPayload) {
      formData.append('image', newPostMedia.featureImgForPayload);
      formData.append('featured', 'image');
    }
    images?.forEach((file, index) => {
      formData.append(`image ${index + 1}`, file);
    });
    const payload = {
      blogId: id,
      formData: formData,
    };
    return payload;
  };

  // Delete Images Payload
  const deleteImagesPayload = () => {
    const ids = deletedIds?.map((id) => {
      return Number(id);
    });
    return {
      ids: ids,
    };
  };

  // HANDLE PUBLISH NEW BLOG
  const handlePublishBlog = async (action) => {
    setLoading(true);
    ApiController.addNewBlogPageCall(createPayload(action), (response) => {
      if (response.success) {
        if (newPostMedia.featureImgForPayload !== '' || images?.length > 0) {
          ApiController.addNewBlogPageUploadImagesCall(
            createPayloadForImagesUpload(response?.data?.blog_id),
            (imgresponse) => {
              if (imgresponse.success) {
                setLoading(false);
                navigate('/pages');
              } else {
                setLoading(false);
              }
            }
          );
        }
      } else {
        setLoading(false);
        const errors = response?.data?.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        setBackendError(errors);
      }
    });
  };
  // HANDLE EDIT BLOG
  const handlEditBlog = async (action) => {
    setLoading(true);
    ApiController.updateBlogCall(createPayload(action, id), (response) => {
      if (response.success) {
        if (newPostMedia.featureImgForPayload !== '' || images?.length > 0) {
          ApiController.addNewBlogPageUploadImagesCall(
            createPayloadForImagesUpload(id),
            (imgresponse) => {
              if (imgresponse.success) {
                // Delete Images API START
                if (deletedIds?.length > 0) {
                  ApiController.deleteBlogImagesCall(
                    deleteImagesPayload(),
                    (deleteResponse) => {
                      if (deleteResponse.success) {
                        setLoading(false);
                        navigate('/pages');
                      }
                    }
                  );
                } else {
                  setLoading(false);
                  navigate('/pages');
                }
                // Delete Images API START
              } else {
                setLoading(false);
                navigate('/pages');
              }
            }
          );
        } else {
          // Delete Images API START
          if (deletedIds?.length > 0) {
            ApiController.deleteBlogImagesCall(
              deleteImagesPayload(),
              (deleteResponse) => {
                if (deleteResponse.success) {
                  setLoading(false);
                  navigate('/pages');
                }
              }
            );
          } else {
            setLoading(false);
            navigate('/pages');
          }
          // Delete Images API START
        }
      } else {
        setLoading(false);
        const errors = response?.data?.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        setBackendError(errors);
      }
    });
  };

  // HANDLE PUBLISH BLOG
  const handleSubmitBlog = async (action) => {
    if (!id) {
      handlePublishBlog(action);
    }
    if (id) {
      handlEditBlog(action);
    }
  };

  // FETCH SINGLE BLOG
  const fetchSingleBlog = async () => {
    setGetBlogPostLoading(true);
    ApiController.getSingleBlogPageDetailsCall(id, (response) => {
      if (response.success) {
        const data = response?.data;
        setGetBlogPostLoading(false);
        const featureImg = data?.blog_images?.filter(
          (item) => item.featured === 'featured'
        )[0];
        const date = getCurrentDate();
        setNewPost((prev) => ({
          ...prev,
          title: data?.title,
          description: data?.description,
          visibility: data?.visibility?.toLowerCase(),
          password: data?.password,
          customURI: data?.end_url ? data?.end_url : 'enter-uri',
          publishDate: data?.scheduled_publish_date?.split('T')[0] || date,
          blogTitle: data?.blog_seo_title || '',
          keywords:
            data?.blog_seo_keywords?.length > 0
              ? data?.blog_seo_keywords?.join(',')
              : '',
          blogMetaDescription: data?.blog_seo_description || '',
        }));
        const textWithURls = replaceBase64ToUrlContent(
          data?.description,
          data?.blog_images
        );
        setContent(textWithURls);
        setNewPostMedia((prev) => ({
          ...prev,
          featureImage: featureImg !== undefined ? featureImg : '',
          featureFrom: 'link',
        }));
        const linksData = data?.keywords?.map((item) => ({
          keyword: item?.word,
          link: item?.link,
        }));
        setLinks(linksData);
        setTagsList(data?.tags);
        setBlogData(data);
      } else {
        setGetBlogPostLoading(false);
      }
    });
  };

  // PREVIEW BUTTON VALIDATION
  const previewButtonValidation = () => {
    const { title } = newPost;
    const { featureImage } = newPostMedia;
    if (!title || !content || !featureImage || tagsList.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  // USEEFFECT FOR GET BLOG
  useEffect(() => {
    if (id) {
      fetchSingleBlog();
    }
  }, [id]);

  return (
    <React.Fragment>
      <div className='main-container '>
        {/* Header BreadCrumb with Buttom Row */}
        <div className='flex flex-row justify-between items-center py-5 px-5'>
          <div>
            <BreadCrumb routes={[{ name: 'Pages' }]} />
            <div
              style={{ fontFamily: 'Inter-Medium', fontSize: 15 }}
              className='text-white pt-1'
            >
              <span>{id ? 'Edit Post' : 'New Post'} </span>
            </div>
          </div>
          <div className='flex flex-row gap-3'>
            <Button
              height={37}
              style={{
                color: previewButtonValidation() ? gray : yellow,
                fontFamily: 'Inter-Medium',
                fontSize: 13,
                textTransform: 'none',
                width: '100px',
                borderRadius: 8,
                boxShadow: 'none',
                backgroundColor: loading ? secondaryColor : 'unset',
              }}
              component='span'
              variant='text'
              loading={loading}
              disabled={previewButtonValidation() || loading}
              onClick={() => handleSubmitBlog('draft')}
            >
              {!loading && 'Save Draft'}
            </Button>
            <Button
              height={37}
              style={{
                borderColor:
                  previewButtonValidation() || loading ? gray : yellow,
                color: previewButtonValidation() || loading ? gray : yellow,
                fontFamily: 'Inter-Medium',
                fontSize: 13,
                textTransform: 'none',
                width: '100px',
                borderRadius: 8,
              }}
              component='span'
              variant='outlined'
              disabled={previewButtonValidation() || loading}
              onClick={handlePreviewPostPage}
            >
              {'Preview'}
            </Button>
            <Button
              height={37}
              className={`!px-5 text-sm !normal-case`}
              style={
                previewButtonValidation() || loading
                  ? Styles?.disableBtn
                  : Styles.activeBtn
              }
              component='span'
              variant='contained'
              disabled={previewButtonValidation() || loading}
              loading={loading}
            >
              <p
                style={
                  previewButtonValidation() || loading
                    ? Styles?.disableBtnText
                    : Styles.activeBtnText
                }
                onClick={() => handleSubmitBlog('publish')}
              >
                {!loading && 'Publish'}
              </p>
            </Button>
          </div>
        </div>
        {getBlogPostLoading ? (
          <InsideSpinner />
        ) : (
          <section
            style={{ borderColor: secondaryColor }}
            className={`flex border-t gap-5`}
          >
            {/* First Section */}
            <div className='w-3/4 flex flex-col h-[calc(100vh-78px)] overflow-auto pb-5 px-3'>
              <div className='w-full'>
                <TextField
                  value={newPost?.title}
                  onChange={(e) => handleOnChange(e)}
                  label='Title'
                  type='text'
                  name='title'
                />
              </div>
              <div>
                <p style={Styles.smallTextWhite} className='mb-1 mt-[20px]'>
                  Description
                </p>
                {/* <div className='bg-white'> */}
                <QuilEditor
                  content={content}
                  setContent={setContent}
                  images={images}
                  setImages={setImages}
                  deletedIds={deletedIds}
                  setDeletedIds={setDeletedIds}
                />
              </div>
            </div>

            {/* Second Section */}
            <div
              className='w-[350px] text-white'
              style={{ backgroundColor: secondaryColor }}
            >
              <BlogTabs
                setNewPost={setNewPost}
                newPost={newPost}
                setContent={setContent}
                handleOnChange={handleOnChange}
                setDeletedIds={setDeletedIds}
                content={content}
                newPostMedia={newPostMedia}
                setNewPostMedia={setNewPostMedia}
                tags={tagsList}
                setTags={setTagsList}
                links={links}
                setLinks={setLinks}
                blogData={blogData}
                id={id}
                backendError={backendError}
              />
            </div>
          </section>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddNewBlogPage;
