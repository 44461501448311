import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import TextField from '../inputField';
import {
  primaryColor,
  secondaryColor,
} from '../../utils/style/GlobalVariables';

const AutoCompleteGooglePlace = ({ setnewCampus, newCampus, label }) => {
  const [address, setAddress] = useState('');
  const handleChangeAddress = (newAddress) => {
    setAddress(newAddress);
  };
  const handleSelectAddress = (newAddress) => {
    setAddress(newAddress);
    setnewCampus((prev) => ({
      ...prev,
      street: newAddress,
    }));
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((coordinates) => {
        setnewCampus((prev) => ({
          ...prev,
          longitude: coordinates.lng,
          latitude: coordinates.lat,
        }));
      })
      .catch((error) => console.error('Error', error));
  };
  return (
    <div className='flex-end mt-5'>
      <PlacesAutocomplete
        value={address}
        onChange={handleChangeAddress}
        onSelect={handleSelectAddress}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className='relative'>
            <TextField
              label={'Street'}
              {...getInputProps({
                placeholder: 'Search Places ...',
              })}
            />
            {loading && (
              <div
                className={`absolute !w-full mt-1 p-1 cursor-pointer text-white bg-[${secondaryColor}] cursor-pointer`}
              >
                Loading...
              </div>
            )}
            {suggestions?.length > 0 && (
              <div className={`absolute !w-full mt-1 p-1 !bg-[#1B1B23]`}>
                {suggestions?.map((suggestion) => {
                  const className = suggestion.active
                    ? ` cursor-pointe text-white bg-[${primaryColor}] cursor-pointer`
                    : `cursor-pointer text-white bg-[${secondaryColor}] cursor-pointer`;
                  return (
                    <div
                      key={suggestion?.placeId}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion?.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AutoCompleteGooglePlace;
