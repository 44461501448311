// Library Imports
import React, { useEffect, useRef } from 'react';

// Local Imports
import AssetsImages from '../../../../assets';
import { mapApiKey } from '../../../../utils/network/constants';
import ApiController from '../../../../utils/network/api';
import { primaryColor, white } from '../../../../utils/style/GlobalVariables';

// Component to Display Map
function MyMapComponent() {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${mapApiKey}`;
      script.async = true;
      script.onload = () => initializeMap();
      document.body.appendChild(script);
    } else {
      initializeMap();
    }
  }, []);

  function initializeMap() {
    if (!mapRef.current) return;

    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 40.5, lng: -112.35 },
      zoom: 6,
      disableDefaultUI: true,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      gestureHandling: 'none',
      styles: [
        {
          elementType: 'geometry',
          stylers: [{ color: '#2A2928' }],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [{ color: '#8D8D8D' }],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#2A2928' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#141515' }],
        },
      ],
    });

    setMarkers(map);
  }

  function setMarkers(map) {
    ApiController.fetchLocationsCall((response) => {
      if (response.success) {
        const image = {
          url: AssetsImages.pin,
          size: new window.google.maps.Size(40, 40),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(0, 32),
        };
        response.data.forEach((location) => {
          new window.google.maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            map,
            icon: image,
            title: location.name,
          });
        });
      }
    });
  }

  return (
    <div style={{ height: '100%', width: '100%' }} ref={mapRef} id='map' />
  );
}

export default MyMapComponent;
